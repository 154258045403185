define("discourse/plugins/discourse-assign/discourse/components/assigned-to-post", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "float-kit/components/d-menu", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _service, _dButton, _dIcon, _i18n, _dMenu, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AssignedToPost = _exports.default = (_class = (_class2 = class AssignedToPost extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "taskActions", _descriptor, this);
    }
    unassign() {
      this.taskActions.unassignPost(this.args.post);
    }
    editAssignment() {
      this.taskActions.showAssignPostModal(this.args.post);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @assignedToUser}}
        {{icon "user-plus"}}
      {{else}}
        {{icon "group-plus"}}
      {{/if}}
  
      <span class="assign-text">
        {{i18n "discourse_assign.assigned_to"}}
      </span>
  
      <a href={{@href}} class="assigned-to-username">
        {{#if @assignedToUser}}
          {{@assignedToUser.username}}
        {{else}}
          {{@assignedToGroup.name}}
        {{/if}}
      </a>
  
      <DMenu @icon="ellipsis-h" class="btn-flat more-button">
        <div class="popup-menu">
          <ul>
            <li>
              <DButton
                @action={{this.unassign}}
                @icon="user-plus"
                @label="discourse_assign.unassign.title"
                class="popup-menu-btn"
              />
            </li>
            <li>
              <DButton
                @action={{this.editAssignment}}
                @icon="group-plus"
                @label="discourse_assign.reassign.title_w_ellipsis"
                class="popup-menu-btn"
              />
            </li>
          </ul>
        </div>
      </DMenu>
    
  */
  {
    "id": "sJ9pBpTw",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[1,[28,[32,0],[\"user-plus\"],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[32,0],[\"group-plus\"],null]],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[10,1],[14,0,\"assign-text\"],[12],[1,\"\\n      \"],[1,[28,[32,1],[\"discourse_assign.assigned_to\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,3],[15,6,[30,2]],[14,0,\"assigned-to-username\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[1,[30,1,[\"username\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,3,[\"name\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[8,[32,2],[[24,0,\"btn-flat more-button\"]],[[\"@icon\"],[\"ellipsis-h\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"popup-menu\"],[12],[1,\"\\n        \"],[10,\"ul\"],[12],[1,\"\\n          \"],[10,\"li\"],[12],[1,\"\\n            \"],[8,[32,3],[[24,0,\"popup-menu-btn\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"unassign\"]],\"user-plus\",\"discourse_assign.unassign.title\"]],null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"li\"],[12],[1,\"\\n            \"],[8,[32,3],[[24,0,\"popup-menu-btn\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"editAssignment\"]],\"group-plus\",\"discourse_assign.reassign.title_w_ellipsis\"]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@assignedToUser\",\"@href\",\"@assignedToGroup\"],false,[\"if\"]]",
    "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/discourse-assign/discourse/components/assigned-to-post.js",
    "scope": () => [_dIcon.default, _i18n.default, _dMenu.default, _dButton.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "taskActions", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "unassign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unassign"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editAssignment", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editAssignment"), _class.prototype)), _class);
});