define("discourse/plugins/discourse-assign/discourse/raw-views/assign-topic-buttons", ["exports", "@ember/object", "@ember/service", "discourse/lib/raw-render-glimmer", "discourse/plugins/discourse-assign/discourse/components/assigned-topic-list-column", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _object, _service, _rawRenderGlimmer, _assignedTopicListColumn, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const ASSIGN_LIST_ROUTES = ["userActivity.assigned", "group.assigned.show"];
  let _class = _exports.default = (_class2 = class _class2 extends _object.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    get html() {
      if (ASSIGN_LIST_ROUTES.includes(this.router.currentRouteName)) {
        return (0, _rawRenderGlimmer.default)(this, "td.assign-topic-buttons", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          <AssignedTopicListColumn @topic={{@data.topic}} />
        */
        {
          "id": "OAPPlb9V",
          "block": "[[[8,[32,0],null,[[\"@topic\"],[[30,1,[\"topic\"]]]],null]],[\"@data\"],false,[]]",
          "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/discourse-assign/discourse/raw-views/assign-topic-buttons.js",
          "scope": () => [_assignedTopicListColumn.default],
          "isStrictMode": true
        }), (0, _templateOnly.default)()), {
          topic: this.topic
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2);
});